// Customer.js
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Switch from "react-switch";
import HorizontalDeviceAgeBarChart from "../components/HorizontalDeviceAgeBarChart";
import CustLineChart from "../components/CustomerLineChart";
import StackedHistoricBarChart from "../components/StackedHistoricBarChart";
import CalendarButton from "../components/Calendar";
import "../styles/Home.css";
import DataTable from "../components/TableCustomer";
import DiDataTable from "../components/DiDataTable";
import {
  calculateHealthyInstalledDevices,
  calculateDeviceIssues,
  deviceIssuesPercentage,
  calculateStockIssues,
  calculateUntracked,
} from "../components/BigNumbers";
import authFetch from "../services/NetworkUtils"
import default_logo from "../images/default_logo.png";
import "../styles/Customer.css";

const Customer = () => {
  const { param1 } = useParams();
  const config = require("../configure.json").API;
  var logo;
  try {
    logo = require(`../images/${param1}.png`);
  } catch (e) {
    logo = require(`../images/default_logo.png`);
  }

  const thirtyDaysAgo = new Date();
  thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30);

  const [apiData, setApiData] = useState({
    api_get_customer_data: null,
    api_device_ages: null,
    api_7days: null,
    api_30days: null,
    api_di: null,
    api_calendar: null,
    api_daily_di: null,
    api_daily_di_by_model: null,
    api_onboardings: null,
    api_stock_issues: null,
    api_stock_issues_history: null,
    api_portal_usage: null,
    api_feature_usage: null,
    api_rental_api_metrics: null,
  });
  const [isLoading, setIsLoading] = useState(false);

  const HealthyInstalledDevices = calculateHealthyInstalledDevices(
    apiData.api_get_customer_data
  );

  const [filterType, setFilterType] = useState("current");
  const [startingDate, setStartingDate] = useState(thirtyDaysAgo); // Default to 30 days ago
  const [finishingDate, setFinishingDate] = useState(new Date());
  const [showIssuesFromSoldCars, setShowIssuesFromSoldCars] = useState(false);
  const [showNotTracked, setShowNotTracked] = useState(true);
  const [statsUpdatedTime, setStatsUpdatedTime] = useState(null)

  const fetchData = (filterType) => {
    setFilterType(filterType);
  };

  useEffect(() => {
    if (localStorage.getItem("showIssuesFromSoldCars") === "true") {
      setShowIssuesFromSoldCars(true);
    } else {
      setShowIssuesFromSoldCars(false);
    }

    if (localStorage.getItem("showNotTracked") === "true") {
      setShowNotTracked(true);
    } else {
      setShowNotTracked(false);
    }

    setIsLoading(true);
    Promise.all([
      authFetch(`${config.api_get_customer_data}?customer_identifier=${param1}`).then((response) =>
        response.data
      ),
      authFetch(`${config.api_device_ages}?customer_identifier=${param1}`).then((response) =>
        response.data
      ),
      authFetch(`${config.api_7days}?customer_identifier=${param1}`).then(
        (response) => response.data
      ),
      authFetch(`${config.api_30days}?customer_identifier=${param1}`).then(
        (response) => response.data
      ),
      authFetch(`${config.api_di}?customer_identifier=${param1}`).then((response) =>
        response.data
      ),
      authFetch(`${config.api_calendar}?customer_identifier=${param1}`).then(
        (response) => response.data
      ),
      authFetch(`${config.api_daily_di}?customer_identifier=${param1}`).then(
        (response) => response.data
      ),
      authFetch(`${config.api_daily_di_by_model}?customer_identifier=${param1}&include_ok_status=false`).then(
        (response) => response.data
      ),
      authFetch(`${config.api_stock_issues}?customer_identifier=${param1}`).then(
        (response) => response.data
      ),
      authFetch(`${config.api_stock_issues_history}?customer_identifier=${param1}`).then(
        (response) => response.data
      ),
      authFetch(`${config.api_onboardings}?customer_identifier=${param1}`).then(
        (response) => response.data
      ),
      authFetch(`${config.api_portal_usage}?customer_identifier=${param1}`).then(
        (response) => response.data
      ),
      authFetch(`${config.api_feature_usage}?customer_identifier=${param1}`).then(
        (response) => response.data
      ),
      authFetch(`${config.api_rental_api_metrics}?customer_identifier=${param1}`).then(
        (response) => response.data
      ),
    ]).then(
      ([
        api_get_customer_data,
        api_device_ages,
        api_7days,
        api_30days,
        api_di,
        api_calendar,
        api_daily_di,
        api_daily_di_by_model,
        api_stock_issues,
        api_stock_issues_history,
        api_onboardings,
        api_portal_usage,
        api_feature_usage,
        api_rental_api_metrics,
      ]) => {
        setIsLoading(false);
        setApiData({
          api_get_customer_data: api_get_customer_data.filter((item) => item.customer_identifier === param1),
          api_device_ages: api_device_ages,
          api_7days: api_7days.filter(
            (item) => item.customer_identifier === param1
          ),
          api_30days: api_30days.filter(
            (item) => item.customer_identifier === param1
          ),
          api_di: api_di.filter((item) => item.customer_identifier === param1),
          api_calendar: api_calendar.filter(
            (item) => item.customer_identifier === param1
          ),
          api_daily_di: api_daily_di.filter(
            (item) => item.customer_identifier === param1
          ),
          api_daily_di_by_model: api_daily_di_by_model.filter(
            (item) => item.customer_identifier === param1
          ),
          api_stock_issues: api_stock_issues.filter(
            (item) => item.customer_identifier === param1
          ),
          api_stock_issues_history: api_stock_issues_history.filter(
            (item) => item.customer_identifier === param1
          ),
          api_onboardings: api_onboardings.filter(
            (item) => item.customer_identifier === param1
          ),
          api_portal_usage: api_portal_usage.filter(
            (item) => item.customer_identifier === param1
          ),
          api_feature_usage: api_feature_usage.filter(
            (item) => item.customer_identifier === param1
          ),
          api_rental_api_metrics: api_rental_api_metrics.filter(
            (item) => item.customer_identifier === param1
          ),
        });
        var latestUpdateTime = null;
        var ts = null;
        api_get_customer_data.forEach((customer) => {
          const { timestamp } = customer;
          ts = new Date(timestamp);
          if (latestUpdateTime == null) {
            latestUpdateTime = ts;
          } else {
            if (latestUpdateTime < ts) {
              latestUpdateTime = ts;
            }
          }
        });
        setStatsUpdatedTime(ts.toTimeString());
      }
    );
  }, [
    config.api_get_customer_data,
    config.api_device_ages,
    config.api_7days,
    config.api_30days,
    config.api_di,
    config.api_calendar,
    config.api_daily_di,
    config.api_daily_di_by_model,
    config.api_stock_issues,
    config.api_stock_issues_history,
    config.api_onboardings,
    config.api_portal_usage,
    config.api_feature_usage,
    config.api_rental_api_metrics,
    param1
  ]);

  const handleStartDate = (date) => {
    const newDate = new Date(date);
    if (newDate > finishingDate) {
      alert("Invalid date selection: End date cannot be earlier than start date");
      window.location.reload();
    } else {
      setStartingDate(newDate);
    }
  };

  const handleFinishDate = (date) => {
    const newFinishingDate = new Date(date);
    if (newFinishingDate < startingDate) {
      alert("Invalid date selection: start date cannot be later than end date");
      window.location.reload();
    } else {
      setFinishingDate(newFinishingDate);
    }
  };

  const handleSoldCarsSwitchChange = (checked) => {
    setShowIssuesFromSoldCars(checked);
    localStorage.setItem("showIssuesFromSoldCars", checked)
  };

  const handleNotTrackedSwitchChange = (checked) => {
    setShowNotTracked(checked);
    localStorage.setItem("showNotTracked", checked)
  };

  // Inside the Customer component
  const ditableData = apiData.api_di;
  const filteredData = apiData.api_calendar?.filter((item) => {
    const itemDate = new Date(item.date).getTime();
    const startDate = new Date(startingDate.getTime()).getTime();
    const finishDate = new Date(finishingDate.getTime() + 86400000).getTime();
    return itemDate >= startDate && itemDate <= finishDate;
  });

  // Extract the unique dates from filteredData
  const custChartLabels = filteredData
    ? filteredData.map((item) =>
        new Date(item.date).toLocaleDateString("en-IE")
      )
    : [];

  return (
    <>
    <div className="fade-cust-in">

    <div style={{ overflowX: "auto" }}>

      <div className="cust-container">

        <div className="user-controls-container-flexbox">

          {/* User controls */}
          <div>
            <div className="user-controls-flexbox">
              <div className="user-controls-element">
                <div>
                  <Switch onChange={handleSoldCarsSwitchChange} checked={showIssuesFromSoldCars} />
                </div>
                {showIssuesFromSoldCars === false ? (<div>Hiding Issues from SOLD vehicles</div>) : (<div>Showing Issues from SOLD vehicles</div>) }
              </div>
              <div className="user-controls-element">
                <div>
                  <Switch onChange={handleNotTrackedSwitchChange} checked={showNotTracked} />
                </div>
                {showNotTracked === false ? (<div>Hiding Vehicles Not Tracked</div>) : (<div>Showing Vehicles Not Tracked</div>) }
              </div>
              <div className="user-controls-element">
                {isLoading ? (
                  <div className="loading-icon"></div>
                ) : (
                  <div>
                    <div>Stats Updated:</div>
                    <div>{statsUpdatedTime}</div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="container-flexbox">

          <div className="number-flexbox">
            <div className="data-container customer-page-logo-container">
              <h6 className="total-headers">Tenant: {param1}</h6>
              <div className="customer-page-logo-content">
                <img
                    className="img customer-page-img"
                    src={logo}
                    alt={param1}
                    width="100%"
                    onError={(e) => (e.target.src = default_logo)}
                />
              </div>
            </div>

            <div className="data-container">
              <p className="number-value">
                <span id="totalFleetSum" style={{ color: "#e61e10" }}>
                  {calculateStockIssues(apiData.api_stock_issues, showNotTracked) }
                </span>
              </p>
              <h6 className="total-headers">Stock Issues</h6>
            </div>

            <div className="data-container">
              <p className="number-value">
                <span id="totalFleetSum" style={{ color: "rgb(255, 166, 0)" }}>
                  {calculateUntracked(apiData.api_stock_issues) }
                </span>
              </p>
              <h6 className="total-headers">Untracked Vehicles</h6>
            </div>

          </div>
          <div className="number-flexbox">

            <div className="data-container">
              <p className="number-value">
                <span
                  id="totalInstalledSum"
                  style={{ color: "rgba(0, 200, 80, 0.7)" }}
                >
                  {HealthyInstalledDevices}
                </span>
              </p>
              <h6 className="total-headers">Healthy Installed Devices</h6>
            </div>

            <div className="data-container">
              <p className="number-value">
                <span id="totalFleetSum" style={{ color: "#e61e10" }}>
                  {calculateDeviceIssues(apiData.api_get_customer_data, showIssuesFromSoldCars, true) }
                </span>
              </p>
              <h6 className="total-headers">Device Issues</h6>
            </div>

            <div className="data-container">
              <p className="number-value">
                <span id="totalIssuesPercentage" style={{ color: "#e61e10" }}>
                  {deviceIssuesPercentage(apiData.api_get_customer_data, showIssuesFromSoldCars, true) }
                </span>
              </p>
              <h6 className="total-headers">Device Issues %</h6>
            </div>
          </div>

          <div className="chart-flexbox">
            <StackedHistoricBarChart
                chartTitle="Onboardings/Offboadings"
                defaultTimeframe="1 week"
                aggregateOn="onboardings"
                stackedBarChartApi={apiData.api_onboardings}
                inclSold={showIssuesFromSoldCars}
                showDownload={false}
                style={{ width: "39%", paddingTop: "40px" }}
              />

            <StackedHistoricBarChart
                chartTitle="Device Issues By Time"
                defaultTimeframe="1 week"
                aggregateOn="issue_type"
                stackedBarChartApi={apiData.api_daily_di}
                inclSold={showIssuesFromSoldCars}
                style={{ width: "64%", paddingTop: "30px" }}
              />
          </div>

          <div className="chart-flexbox">
            <StackedHistoricBarChart
              chartTitle="Historic Device Issues By Model"
              stackedBarChartApi={apiData.api_daily_di_by_model}
              defaultTimeframe="1 month"
              aggregateOn="model_type"
              inclSold={showIssuesFromSoldCars}
              style={{
                width: "98%",
                marginBottom: "1%",
                paddingTop: "40px",
              }}
            />
          </div>

          <div className="chart-flexbox">
            <StackedHistoricBarChart
              chartTitle="Historic Stock Issues"
              stackedBarChartApi={apiData.api_stock_issues_history}
              defaultTimeframe="1 month"
              aggregateOn="stock_type"
              showDownload={false}
              inclNotTrackedStatus={showNotTracked}
              style={{
                width: "98%",
                marginBottom: "1%",
                paddingTop: "40px",
              }}
            />
          </div>
        </div>

        <div className="tab-content-flexbox">
            <div id="btn-table">
              <h4 className="table-name">Customer Summary</h4>

              <div id="btn-cust">
                <button type="button" onClick={() => fetchData("30days")}>
                  1 Month
                </button>
                <button type="button" onClick={() => fetchData("7days")}>
                  1 Week
                </button>
                <button type="button" onClick={() => fetchData("current")}>
                  Today
                </button>
              </div>
            </div>

            <div>
              {/* TableCustomer component */}
              {(() => {
                let filteredData = [];
                if (filterType === "30days" && apiData.api_30days) {
                  filteredData = apiData.api_30days;
                } else if (filterType === "7days" && apiData.api_7days) {
                  filteredData = apiData.api_7days;
                } else if (filterType === "current" && apiData.api_get_customer_data) {
                  filteredData = apiData.api_get_customer_data;
                }

                return (
                  <div className="main-table">
                    <DataTable data={filteredData} filterType={filterType} />
                  </div>
                );
              })()}
            </div>
        </div>

        <div className="container-flexbox">
          <div className="chart-flexbox">
            <StackedHistoricBarChart
                chartTitle="Rental API requests"
                defaultTimeframe="1 month"
                aggregateOn="rental_api_metrics"
                stackedBarChartApi={apiData.api_rental_api_metrics}
                style={{ width: "100%", paddingTop: "30px" }}
              />
          </div>
        </div>

        <div className="container-flexbox">
          <div className="chart-flexbox">
            <StackedHistoricBarChart
                chartTitle="Portal Feature Usage"
                defaultTimeframe="1 month"
                aggregateOn="feature_usage"
                legendPosition="bottom"
                stackedBarChartApi={apiData.api_feature_usage}
                style={{ width: "100%", paddingTop: "30px" }}
              />
          </div>
        </div>

        <div className="container-flexbox">
          <div className="chart-flexbox">
            <StackedHistoricBarChart
                chartTitle="Portal Page Counts By Role"
                defaultTimeframe="1 month"
                aggregateOn="portal_by_role"
                stackedBarChartApi={apiData.api_portal_usage}
                style={{ width: "100%", paddingTop: "30px" }}
              />
          </div>
        </div>

        <div className="cust-container-flexbox">
          <div id="cust-barchart-container-row">
              <HorizontalDeviceAgeBarChart
                apiData={apiData.api_device_ages}
                chartTitle="Device Age Ranges"
                aggregateOn="age_type"
              />
          </div>

          <div className="cust-linechart-container">
            <span className="calendar-btn" id="btn-cust">
              <CalendarButton
                onSelectDate={handleStartDate}
                buttonLabel="Start Date"
              />
              <CalendarButton
                onSelectDate={handleFinishDate}
                buttonLabel="End Date"
              />
            </span>
            {/*Line Chart  */}
            {filteredData && (
              <CustLineChart
                chartLabels={custChartLabels}
                custLineChartApi={filteredData}
                inclSold={showIssuesFromSoldCars}
              />
            )}
          </div>
        </div>


        <div id="btn-table">
          <h4 className="table-name ">
            Device Issues
          </h4>
        </div>

        <div className="main-table">
          <DiDataTable apiData={ditableData} />
        </div>
      </div>
    </div>
    </div>
    </>
  );
};

export default Customer;
