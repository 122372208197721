// Customer.js
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Switch from "react-switch";
import StackedHistoricBarChart from "../components/StackedHistoricBarChart";
import "../styles/Home.css";
import DiDataTable from "../components/DiDataTable";
import authFetch from "../services/NetworkUtils"
import "../styles/Manufacturer.css";

const Manufacturer = () => {
  const { param1 } = useParams();
  const config = require("../configure.json").API;
  const thirtyDaysAgo = new Date();
  thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30);
  const [di, setDI] = useState(null);
  const [diDailyDataManu, setDailyDIManu] = useState(null);
  const [modelBreakdown, setModelBreakdown] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [showTrial, setShowTrial] = useState(false);
  const [showIssuesFromSoldCars, setShowIssuesFromSoldCars] = useState(false);
  const [statsUpdatedTime, setStatsUpdatedTime] = useState(null)

  useEffect(() => {
    if (localStorage.getItem("showIssuesFromSoldCars") === "true") {
      setShowIssuesFromSoldCars(true);
    } else {
      setShowIssuesFromSoldCars(false);
    }

    setIsLoading(true);
    Promise.all([
      authFetch(`${config.api_di}?manufacturer=${param1}&include_trial=${showTrial}`).then((response) =>
        response.data
      ),
      authFetch(`${config.api_daily_di_manu}?manufacturer=${param1}&include_trial=${showTrial}`).then(
        (response) => response.data
      ),
    ]).then(
      ([
        api_di,
        api_daily_di_manu
      ]) => {
        setIsLoading(false);
        setDI(api_di);
        setDailyDIManu(api_daily_di_manu);
        var latestUpdateTime = null;
        var ts = null;
        api_di.forEach((item) => {
          const { timestamp } = item;
          ts = new Date(timestamp);
          if (latestUpdateTime == null) {
            latestUpdateTime = ts;
          } else {
            if (latestUpdateTime < ts) {
              latestUpdateTime = ts;
            }
          }
        });
        if (ts) {
          setStatsUpdatedTime(ts.toTimeString());
        }
        var model_set = new Set();
        var model_list = [];
        var thirtyDaysAgo = new Date().getTime() - (30 * 24 * 60 * 60 * 1000)
        api_daily_di_manu.forEach((item) => {
          const { model_type, timestamp } = item;
          ts = new Date(timestamp);
          if (ts > thirtyDaysAgo) {
            if (!model_set.has(model_type)) {
              model_list[model_type] = [];
              model_set.add(model_type);
            }
          }
          if (model_type in model_list) {
            model_list[model_type].push(item);
          }
        });
        setModelBreakdown(model_list);
      }
    );
  }, [
    config.api_di,
    config.api_daily_di_manu,
    param1,
    showTrial
  ]);

  const handleSoldCarsSwitchChange = (checked) => {
    setShowIssuesFromSoldCars(checked);
    localStorage.setItem("showIssuesFromSoldCars", checked)
  };

  const handleTrialSwitchChange = (checked) => {
    setShowTrial(checked);
    localStorage.setItem("showTrial", checked)
  };

  function renderModels(arr) {
      if (arr) {
         return (
             <div className="item-list">
              {
                Object.keys(arr).map((key, index) => (
                    <div className="chart-flexbox">
                     <StackedHistoricBarChart
                       chartTitle={key}
                       stackedBarChartApi={arr[key]}
                       defaultTimeframe="1 month"
                       aggregateOn="issue_type"
                       inclSold={showIssuesFromSoldCars}
                       style={{
                         width: "98%",
                         marginBottom: "1%",
                         paddingTop: "40px",
                       }}
                     />
                   </div>
               ))
              }
              </div>
      )}
  }


  return (
    <>
    <div className="fade-cust-in">

    <div style={{ overflowX: "auto" }}>

      <div className="manufacturer-container">

        <div className="user-controls-container-flexbox">

          {/* User controls */}
          <div>
            <div className="user-controls-flexbox">
              <div className="user-controls-element">
                <div>
                  <Switch onChange={handleSoldCarsSwitchChange} checked={showIssuesFromSoldCars} />
                </div>
                {showIssuesFromSoldCars === false ? (<div>Hiding Issues from SOLD vehicles</div>) : (<div>Showing Issues from SOLD vehicles</div>) }
              </div>
              <div className="user-controls-element">
                <div>
                  <Switch onChange={handleTrialSwitchChange} checked={showTrial} />
                </div>
                {showTrial === false ? (<div>Hiding Issues From Trial</div>) : (<div>Showing Issues From Trial</div>) }
              </div>
              <div className="user-controls-element">
                {isLoading ? (
                  <div className="loading-icon"></div>
                ) : (
                  <div>
                    <div>Stats Updated:</div>
                    <div>{statsUpdatedTime}</div>
                  </div>
                )}
              </div>
              <div className="user-controls-element">
                <div>
                  <div>Manufacturer: {param1}</div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container-flexbox">

          <div className="chart-flexbox">
            <StackedHistoricBarChart
                chartTitle="All models"
                defaultTimeframe="1 month"
                aggregateOn="issue_type"
                stackedBarChartApi={diDailyDataManu}
                inclSold={showIssuesFromSoldCars}
                style={{
                    width: "98%",
                    marginBottom: "1%",
                    paddingTop: "40px",
                }}
              />
          </div>

          {renderModels(modelBreakdown)}

        </div>

        <div id="btn-table">
          <h4 className="table-name ">
            Device Issues
          </h4>
        </div>

        <div className="main-table">
          <DiDataTable apiData={di} />
        </div>
      </div>
    </div>
    </div>
    </>
  );
};

export default Manufacturer;
