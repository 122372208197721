import React, { useEffect, useState } from "react";
import Switch from "react-switch";
import Select from 'react-select';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import HorizontalDeviceAgeBarChart from "../components/HorizontalDeviceAgeBarChart";
import TableComponent from "../components/TableHome";
import DiDataTable from "../components/DiDataTable";
import LineChart from "../components/LineChart";
import StackedHistoricBarChart from "../components/StackedHistoricBarChart"
import StackedCustomerBarChart from "../components/StackedCustomerBarChart"
import BarChart from "../components/BarChart";
import Map from "../components/Map";
import "../styles/Home.css";
import {
  calculateTotalInstalledFleet,
  calculateTotalCurrentFleet,
  calculateTotalFleetCoverage,
  deviceIssuesPercentage,
  calculateDeviceIssues,
  calculateVehiclesNoStation,
  calculateRentalStationsWithNoRecord,
  calculateStationsWithNoGeofence,
  calculateHealthyInstalledDevices,
  calculateStockIssues,
  calculateUntracked,
} from "../components/BigNumbers";
import ExcelCustTableDownloadButton from "../components/ExcelCustTableDownloadButton";
import ExcelStationIssuesDownloadButton from "../components/ExcelStationIssuesDownloadButton";
import DeviceIssuesDownloadButton from "../components/DeviceIssuesDownloadButton";
import authFetch from "../services/NetworkUtils"

const config = require("../configure.json").API;

const Home = () => {
  const [tableData, setTableData] = useState(null);
  const [ditableData, setdiTableData] = useState(null);
  const [dailyActionStats, setDailyActionStats] = useState(null);
  //const [rentalApiStats, setRentalApiStats] = useState(null);
  const [featureUsage, setFeatureUsage] = useState(null);
  const [stockIssues, setStockIssues] = useState(null);
  const [stockIssuesHistory, setStockIssuesHistory] = useState(null);
  const [diDailyData, setDailyDI] = useState(null);
  const [diDailyDataByModel, setDailyDIByModel] = useState(null);
  const [rmInstalledDeviceAges, setRmInstalledDeviceAges] = useState(null);
  const [custInstalledDeviceAges, setCustInstalledDeviceAges] = useState(null);
  const [lineChartData, setLineChartData] = useState(null);
  const [locationData, setLocationData] = useState(null);
  const [statsUpdatedTime, setStatsUpdatedTime] = useState(null)
  const [isLoading, setIsLoading] = useState(false);
  const [sortMainColumn, setSortMainColumn] = useState(null);
  const [sortMainDirection, setSortMainDirection] = useState("ascend");
  const [sortsecColumn, setSortsecColumn] = useState(null);
  const [sortsecDirection, setSortsecDirection] = useState("ascend");
  const [selectedCountry, setSelectedCountry] = useState("Ireland");
  const [tabIndex, setTabIndex] = useState(0);
  const [currentDate, setCurrentDate] = useState("");
  const [onboardingData, setOnboardingData] = useState("");
  const [showIssuesFromSoldCars, setShowIssuesFromSoldCars] = useState(false);
  const [showTrial, setShowTrial] = useState(false);
  const [showNotTracked, setShowNotTracked] = useState(true);
  const [showOkStatus, setShowOkStatus] = useState(false);
  const [tenantList, setTenantList] = useState([]);
  const [manufacturerList, setManufacturerList] = useState([]);
  const [countriesData, setCountriesData] = useState([
    ["Country", "Total Devices"],
    ["Australia", 0],
    ["Iceland", 0],
    ["Ireland", 0],
    ["Portugal", 0],
    ["Puerto Rico", 0],
    ["United States", 0],
    ["Cyprus", 0],
    ["Spain", 0],
    ["Croatia", 0],
    ["Austria", 0],
    ["Greece",0],
    ["United Kingdom", 0]
  ]);

  const totalCurrentFleet = calculateTotalCurrentFleet(tableData);
  const totalFleetCoverage = calculateTotalFleetCoverage(tableData);
  const vehiclesNoStation = calculateVehiclesNoStation(tableData);
  const rentalStationsWithNoRecord = calculateRentalStationsWithNoRecord(tableData);
  const stationsWithNoGeofence = calculateStationsWithNoGeofence(tableData);

  const handleMainSort = (column) => {
    if (sortMainColumn === column) {
      setSortMainDirection((prevDirection) =>
        prevDirection === "ascend" ? "descend" : "ascend"
      );
    } else {
      setSortMainColumn(column);
      setSortMainDirection("ascend");
    }
  };

  const handlesecSort = (column) => {
    if (sortsecColumn === column) {
      setSortsecDirection((prevDirection) =>
        prevDirection === "ascend" ? "descend" : "ascend"
      );
    } else {
      setSortsecColumn(column);
      setSortsecDirection("ascend");
    }
  };

  const handleSoldCarsSwitchChange = (checked) => {
    setShowIssuesFromSoldCars(checked);
    localStorage.setItem("showIssuesFromSoldCars", checked)
  };

  const handleOkStatusSwitchChange = (checked) => {
    setShowOkStatus(checked);
    localStorage.setItem("showOkStatus", checked)
  };

  const handleTrialSwitchChange = (checked) => {
    setShowTrial(checked);
    localStorage.setItem("showTrial", checked)
  };

  const handleNotTrackedSwitchChange = (checked) => {
    setShowNotTracked(checked);
    localStorage.setItem("showNotTracked", checked)
  };

  const handleTenantSelect = (selectedOption) => {
    if (selectedOption !== null) {
      window.open( "customer/" + selectedOption.value, '_blank').focus();
    }
  };

  const handleManufacturerSelect = (selectedOption) => {
    if (selectedOption !== null) {
      window.open( "manufacturer/" + selectedOption.value, '_blank').focus();
    }
  };

  const handleTabOnSelect = (selectedIndex) => {
      localStorage.setItem("tabIndex", selectedIndex);
      setTabIndex(selectedIndex);
  }

  const headerMain = [
    "id",
    "customer_name",
    "current_fleet",
    "fleet_coverage",
    "stock_issues",
    "stock_issues_percentage",
    "device_issues",
    "device_issues_percentage",
    "devices_installed_on_current_fleet",
    "devices_installed_on_non_fleet_vehicles",
    "additional_fleet_potential",
    "spare_devices",
  ];

  const headerMainExclSold = [
    "id",
    "customer_name",
    "current_fleet",
    "fleet_coverage",
    "stock_issues",
    "stock_issues_percentage",
    "device_issues_excl_sold",
    "device_issues_excl_sold_percentage",
    "devices_installed_on_current_fleet",
    "devices_installed_on_non_fleet_vehicles",
    "additional_fleet_potential",
    "spare_devices",
  ];

  const headersec = [
    "id",
    "customer_name",
    "devices_on_sold_vehicles",
    "unseeable_devices_lost_faulty",
    "unseeable_devices_age_deprecated",
    "total_devices_ever_sent",
    "daily_users",
    "available_users",
    "vehicles_with_null_rental_station",
    "rental_stations_with_no_record",
    "stations_with_no_geofence"
  ];


  useEffect(() => {

    if (localStorage.getItem("showIssuesFromSoldCars") === "true") {
      setShowIssuesFromSoldCars(true);
    } else {
      setShowIssuesFromSoldCars(false);
    }

    if (localStorage.getItem("showTrial") === "true") {
      setShowTrial(true);
    } else {
      setShowTrial(false);
    }

    if (localStorage.getItem("showNotTracked") === "true") {
      setShowNotTracked(true);
    } else {
      setShowNotTracked(false);
    }

    const restoredTabIndex = localStorage.getItem("tabIndex");
    if (restoredTabIndex !== null) {
      setTabIndex(parseInt(restoredTabIndex));
    }

    var loadingCount = 0;
    const checkLoadingIcon = function (decrement) {
      loadingCount = loadingCount - decrement;
      if (loadingCount <= 0) {
        setIsLoading(false);
      }
    }
    const incrLoadingIcon = function (increment) {
      setIsLoading(true);
      loadingCount = loadingCount + increment;
    }

    incrLoadingIcon(1);
    Promise.all([
      authFetch(`${config.api_tenant_list}`).then((response) => response.data),
    ])
      .then(
        ([
          api_tenant_list,
        ]) => {
          checkLoadingIcon(1);
          setTenantList(api_tenant_list.map((val) => {
                          return { value: val.tenantId, label: val.clientName };
                        }));
        }
      )
      .catch((error) => {
        checkLoadingIcon(1);
        console.error(error);
      });

    incrLoadingIcon(3);
    Promise.all([
      authFetch(`${config.api_di}?include_trial=${showTrial}`).then((response) => response.data),
      authFetch(`${config.api_total_installs}?include_trial=${showTrial}`).then((response) => response.data),
      authFetch(`${config.api_onboardings}`).then((response) => response.data),
    ])
      .then(
        ([
          api_di,
          api_total_installs,
          api_onboardings,
        ]) => {
          checkLoadingIcon(3);
          setdiTableData(api_di);
          setLineChartData(api_total_installs);
          setOnboardingData(api_onboardings);
          findManufacturerList(api_di);
        }
      )
      .catch((error) => {
        checkLoadingIcon(3);
        console.error(error);
      });

    incrLoadingIcon(2);
    Promise.all([
      authFetch(`${config.api_stock_issues}?include_trial=${showTrial}`).then((response) => response.data),
      authFetch(`${config.api_stock_issues_history}?include_trial=${showTrial}`).then((response) => response.data),
    ])
      .then(
        ([
          api_stock_issues,
          api_stock_issues_history,
        ]) => {
          checkLoadingIcon(2);
          setStockIssues(api_stock_issues);
          setStockIssuesHistory(api_stock_issues_history);
        }
      )
      .catch((error) => {
        checkLoadingIcon(2);
        console.error(error);
      });

    incrLoadingIcon(3);
    Promise.all([
      authFetch(`${config.api_daily_di}?include_trial=${showTrial}`).then((response) => response.data),
      authFetch(`${config.api_daily_di_by_model}?include_trial=${showTrial}&include_ok_status=${showOkStatus}`).then((response) => response.data),
      authFetch(`${config.api_daily_action_stats}?include_trial=${showTrial}`).then((response) => response.data),
    ])
      .then(
        ([
          apiDailyDI,
          apiDailyDIByModel,
          api_daily_action_stats,
        ]) => {
          checkLoadingIcon(3);
          setDailyDI(apiDailyDI);
          setDailyDIByModel(apiDailyDIByModel);
          setDailyActionStats(api_daily_action_stats);
        }
      )
      .catch((error) => {
        checkLoadingIcon(3);
        console.error(error);
      });

    incrLoadingIcon(2);
    Promise.all([
      authFetch(`${config.api_get_customer_data}?include_trial=${showTrial}`).then((response) => response.data),
      authFetch(`${config.api_location}?include_trial=${showTrial}`).then((response) => response.data),
    ])
      .then(
        ([
          api_get_customer_data,
          locationData,
        ]) => {
          checkLoadingIcon(2);
          setTableData(api_get_customer_data);
          setLocationData(locationData);

          // Create a copy of the countriesData array to update
          const updatedCountriesData = [...countriesData];

          // Iterate through tableData and update countriesData
          var latestUpdateTime = null;
          var ts = null;
          api_get_customer_data.forEach((customer) => {
            const { customer_name, devices_installed_on_current_fleet, timestamp } = customer;
            ts = new Date(timestamp);
            if (latestUpdateTime == null) {
              latestUpdateTime = ts;
            } else {
              if (latestUpdateTime < ts) {
                latestUpdateTime = ts;
              }
            }
            const matchingLocation = locationData.find(
              (item) => item.clientName === customer_name
            );

            if (matchingLocation) {
              const countryIndex = updatedCountriesData.findIndex(
                (country) => country[0] === matchingLocation.location
              );

              if (countryIndex !== -1) {
                updatedCountriesData[countryIndex][1] += devices_installed_on_current_fleet;
              }
            }
          });
          setStatsUpdatedTime(ts.toTimeString());
          setCountriesData(updatedCountriesData);
        }
      )
      .catch((error) => {
        checkLoadingIcon(2);
        console.error(error);
      });

    incrLoadingIcon(2);
    Promise.all([
      //authFetch(`${config.api_rental_api_metrics}`).then((response) => response.data),
      authFetch(`${config.api_feature_usage}`).then((response) => response.data),
    ])
      .then(
        ([
          //api_rental_api_metrics,
          api_feature_usage,
        ]) => {
          checkLoadingIcon(2);
          //setRentalApiStats(api_rental_api_metrics);
          setFeatureUsage(api_feature_usage);
        }
      )
      .catch((error) => {
        checkLoadingIcon(2);
        console.error(error);
      });

    incrLoadingIcon(2);
    Promise.all([
      authFetch(`${config.api_device_ages}?age_type=2&include_trial=${showTrial}`).then((response) => response.data),
      authFetch(`${config.api_device_ages}?age_type=4&include_trial=${showTrial}`).then((response) => response.data),
    ])
      .then(
        ([
          api_rm_installed_device_ages,
          api_cust_installed_device_ages,
        ]) => {
          checkLoadingIcon(2);
          setRmInstalledDeviceAges(api_rm_installed_device_ages);
          setCustInstalledDeviceAges(api_cust_installed_device_ages);
        }
      )
      .catch((error) => {
        checkLoadingIcon(2);
        console.error(error);
      });

    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, "0");
    const day = String(now.getDate()).padStart(2, "0");
    const hours = String(now.getHours()).padStart(2, "0");
    const minutes = String(now.getMinutes()).padStart(2, "0");

    const formattedDate = `${year}-${month}-${day}-${hours}-${minutes}`;
    setCurrentDate(formattedDate);

    // Workaround for countriesData, but it's not a right long-term soln
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showIssuesFromSoldCars, showTrial, showOkStatus, tabIndex]);


  const handleCountrySelect = (countryName) => {
    setSelectedCountry(countryName);
  };

  const findManufacturerList = (di) => {
    let manu_set = new Set();
    di.forEach((item) => {
          const { manufacturer } = item;
          manu_set.add(manufacturer);
        });
    let as_array = Array.from(manu_set);
    setManufacturerList(as_array.map((val) => {
                          return { value: val, label: val };
                        }));
  };

  const fileName = `RM_Data_${currentDate}.xlsx`;
  const stationFileName = `Station_Data_${currentDate}.xlsx`;
  const deviceIssuesExcelUrl = `${config.api_get_device_issues_excel}`;
  const deviceIssuesCsvUrl = `${config.api_get_device_issues_csv}`;
  const firmwareMetricsExcelUrl = `${config.api_get_firmware_metrics_excel}`;
  const installInfoExcelUrl = `${config.api_get_install_info_excel}`;

  return (
  <>
    <div className="fade-in">

      <div style={{ overflowX: "auto" }}>

        <div id="Box1"  className="fade-in">

            <div className="user-controls-container-flexbox">

              {/* User controls */}
              <div className="user-controls-flexbox">
                <div className="user-controls-element">
                  <div>
                    <Switch onChange={handleSoldCarsSwitchChange} checked={showIssuesFromSoldCars} />
                  </div>
                  {showIssuesFromSoldCars === false ? (<div>Hiding Device Issues from SOLD vehicles</div>) : (<div>Showing Device Issues from SOLD vehicles</div>) }
                </div>
                <div className="user-controls-element">
                  <div>
                    <Switch onChange={handleTrialSwitchChange} checked={showTrial} />
                  </div>
                  {showTrial === false ? (<div>Hiding Issues From Trial</div>) : (<div>Showing Issues From Trial</div>) }
                </div>
                <div className="user-controls-element">
                  <div>
                    <Switch onChange={handleNotTrackedSwitchChange} checked={showNotTracked} />
                  </div>
                  {showNotTracked === false ? (<div>Hiding Vehicles Not Tracked</div>) : (<div>Showing Vehicles Not Tracked</div>) }
                </div>
                <div className="user-controls-element">
                  {isLoading ? (
                    <div className="loading-icon"></div>
                  ) : (
                    <div>
                      <div>Stats Updated:</div>
                      <div>{statsUpdatedTime}</div>
                    </div>
                  )}
                </div>
                <div className="user-controls-element">
                  <div>View Manufacturer</div>
                    <Select className="user-controls-tenant-select"
                      onChange={handleManufacturerSelect}
                      options={manufacturerList}
                    />
                </div>
                <div className="user-controls-element">
                  <div>View Tenant</div>
                    <Select className="user-controls-tenant-select"
                      onChange={handleTenantSelect}
                      options={tenantList}
                    />
                </div>
              </div>
            </div>

          <Tabs
             className="tab-container"
             selectedTabClassName="tab-selected"
             selectedIndex={tabIndex}
             onSelect={(index) => handleTabOnSelect(index)}>
            <TabList>
              <Tab className="tab-option">Device Issues</Tab>
              <Tab className="tab-option">Stock Issues</Tab>
              <Tab className="tab-option">Usage Metrics</Tab>
              <Tab className="tab-option">Customer Summaries</Tab>
            </TabList>

            <TabPanel>
              <div>
                <div className="tab-content-flexbox">

                  {/* Row for device issue counts */}
                  <div className="number-flexbox">
                    <div className="data-container" style={{ width: "32%" }}>
                      <p className="number-value">
                        <span
                          id="totalInstalledSum"
                          style={{ color: "rgba(0, 200, 80, 0.7)" }}
                        >
                          {calculateHealthyInstalledDevices(tableData, showIssuesFromSoldCars, showTrial)}
                        </span>
                      </p>
                      <h6 className="total-headers">Healthy Installed Devices</h6>
                    </div>

                    <div className="data-container" style={{ width: "28%" }}>
                      <p className="number-value">
                        <span id="totalFleetSum" style={{ color: "#e61e10" }}>
                          {calculateDeviceIssues(tableData, showIssuesFromSoldCars, showTrial) }
                        </span>
                      </p>
                      <h6 className="total-headers">Device Issues</h6>
                    </div>

                    <div className="data-container" style={{ width: "28%" }}>
                      <p className="number-value">
                        <span
                          id="totalIssuesPercentage"
                          style={{ color: "#e61e10" }}
                        >
                          {deviceIssuesPercentage(tableData, showIssuesFromSoldCars, showTrial) }
                        </span>
                      </p>
                      <h6 className="total-headers">Issues / Installed</h6>
                    </div>

                    <div className="data-container" style={{ width: "15%" }}>
                      <div className="issues-download-all-container">
                        <div>
                          <div className="issues-download-container">
                            <DeviceIssuesDownloadButton prefix="Device Issues" excelUrl={deviceIssuesExcelUrl} />
                            <div className="issues-download-text issues-download-text">Device Issues Excel</div>
                          </div>
                          <div className="issues-download-container">
                            <DeviceIssuesDownloadButton prefix="Device Issues CSV" excelUrl={deviceIssuesCsvUrl} />
                            <div className="issues-download-text issues-download-text">Device Issues CSV</div>
                          </div>
                          <div className="issues-download-container">
                            <DeviceIssuesDownloadButton prefix="Firmware Pivots" excelUrl={firmwareMetricsExcelUrl} />
                            <div className="issues-download-text issues-download-text">Firmware Pivots</div>
                          </div>
                          <div className="issues-download-container">
                            <DeviceIssuesDownloadButton prefix="Install Info" excelUrl={installInfoExcelUrl} />
                            <div className="issues-download-text issues-download-text">Install Info</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Row for set of numbers related to fleet coverage */}
                  <div className="number-flexbox">
                    {/* Display total fleet */}
                    <div className="data-container">
                      <p className="small-number-value">
                        <span id="totalFleetSum" style={{ color: "#009879" }}>
                          {totalCurrentFleet}
                        </span>
                      </p>
                      <h6 className="total-headers">Total Fleet</h6>
                    </div>

                    {/* Display total device installs */}
                    <div className="data-container">
                      <p className="small-number-value">
                        <span id="totalInstalledSum" style={{ color: "#4BC0C0" }}>
                        {calculateTotalInstalledFleet(tableData, showIssuesFromSoldCars, showTrial)}

                        </span>
                      </p>
                      <h6 className="total-headers">Total Installed Devices</h6>
                    </div>

                    {/* Display percentage of total device installs */}
                    <div className="data-container">
                      <p className="small-number-value">
                        <span id="totalFleetCoverage" style={{ color: "#ffa600" }}>
                          {totalFleetCoverage}
                        </span>
                      </p>
                      <h6 className="total-headers">Fleet Coverage %</h6>
                    </div>
                  </div>

                  <div className="chart-flexbox">

                    {/* Onboardings/Offboardings by customer by date */}
                    <StackedHistoricBarChart
                      chartTitle="Onboardings/Offboadings"
                      stackedBarChartApi={onboardingData}
                      defaultTimeframe="1 week"
                      aggregateOn="onboardings"
                      inclSold={showIssuesFromSoldCars}
                      inclOkStatus={showOkStatus}
                      showDownload={false}
                      style={{ width: "39%", paddingTop: "40px" }}
                    />

                    {/* Line Chart total device installs */}
                    <LineChart
                      lineChartApi={lineChartData}
                      inclSold={showIssuesFromSoldCars}
                      style={{ width: "60%", paddingTop: "40px" }}
                    />
                  </div>

                </div>
              </div>

              <div id="Box2" className="tab-content-flexbox">

                  {/* User controls */}
                  <div>
                    <h2 className="section-title">Historic Device Issues</h2>
                    <div className="user-controls-flexbox">
                      <div className="user-controls-element">
                        <div>
                          <Switch onChange={handleOkStatusSwitchChange} checked={showOkStatus} />
                        </div>
                        {showOkStatus === false ? (<div>Hiding 'OK' Status</div>) : (<div>Showing 'OK' Status</div>) }
                      </div>
                      <div className="user-controls-element">
                        {isLoading &&
                          <div className="loading-icon"></div>
                        }
                      </div>
                    </div>
                  </div>

                  {/* Device Issue Stacked Bar Chart - by issue type by date */}
                  <StackedHistoricBarChart
                    chartTitle="Issues By Type"
                    stackedBarChartApi={diDailyData}
                    defaultTimeframe="1 month"
                    aggregateOn="issue_type"
                    inclSold={showIssuesFromSoldCars}
                    inclOkStatus={showOkStatus}
                    style={{
                      width: "98%",
                      marginBottom: "1%",
                      paddingTop: "40px",
                    }}
                  />

                  {/* Device Issue Stacked Bar Chart - by customer by date */}
                  <StackedHistoricBarChart
                    chartTitle="Issues By Customer"
                    stackedBarChartApi={diDailyData}
                    defaultTimeframe="1 month"
                    aggregateOn="customers"
                    inclSold={showIssuesFromSoldCars}
                    inclOkStatus={showOkStatus}
                    style={{
                      width: "98%",
                      marginBottom: "1%",
                      paddingTop: "40px",
                    }}
                  />

                  {/* Device Issue Stacked Bar Chart - by model by date */}
                  <StackedHistoricBarChart
                    chartTitle="Issues By Model"
                    stackedBarChartApi={diDailyDataByModel}
                    defaultTimeframe="1 month"
                    aggregateOn="model_type"
                    inclSold={showIssuesFromSoldCars}
                    style={{
                      width: "98%",
                      marginBottom: "1%",
                      paddingTop: "40px",
                    }}
                  />

                  {/* Actions Stacked Bar Chart - by issue type by date */}
                  <StackedHistoricBarChart
                    chartTitle="RM Action vs Customer Action"
                    stackedBarChartApi={dailyActionStats}
                    defaultTimeframe="1 month"
                    aggregateOn="actions_issues"
                    inclSold={showIssuesFromSoldCars}
                    inclOkStatus={showOkStatus}
                    style={{
                      width: "98%",
                      marginBottom: "1%",
                      paddingTop: "40px",
                    }}
                  />

              </div>

              <div className="tab-content-flexbox">
                <div className="main-table">
                    <DiDataTable apiData={ditableData} />
                </div>
              </div>
            </TabPanel>


            <TabPanel>
              <div>
                <div className="tab-content-flexbox">

                  {/* Row for stock issue counts */}
                  <div className="number-flexbox">

                    {/* Display total fleet */}
                    <div className="data-container">
                      <p className="number-value">
                        <span id="totalFleetSum" style={{ color: "#009879" }}>
                          {totalCurrentFleet}
                        </span>
                      </p>
                      <h6 className="total-headers">Total Fleet</h6>
                    </div>

                    <div className="data-container">
                      <p className="number-value">
                        <span id="totalFleetSum" style={{ color: "#e61e10" }}>
                          {calculateStockIssues(stockIssues, showNotTracked) }
                        </span>
                      </p>
                      <h6 className="total-headers">Stock Issues</h6>
                    </div>

                    <div className="data-container">
                      <p className="number-value">
                        <span
                          id="totalIssuesPercentage"
                          style={{ color: "rgb(255, 166, 0)" }}
                        >
                          {calculateUntracked(stockIssues) }
                        </span>
                      </p>
                      <h6 className="total-headers">Untracked Vehicles</h6>
                    </div>
                  </div>

                  {/* Row for station metrics */}
                  <div className="number-flexbox">
                    <div className="data-container">
                      <p className="small-number-value">
                        <span
                          style={{ color: "#ffa600" }}
                        >
                          {vehiclesNoStation}
                        </span>
                      </p>
                      <h6 className="total-headers">Vehicles With No Rental Station</h6>
                    </div>

                    <div className="data-container">
                      <p className="small-number-value">
                        <span
                          style={{ color: "#ffa600" }}
                        >
                          {rentalStationsWithNoRecord}
                        </span>
                      </p>
                      <h6 className="total-headers">Vehicle Rental Stations With No Record</h6>
                    </div>

                    <div className="data-container">
                      <p className="small-number-value">
                        <span
                          style={{ color: "#ffa600" }}
                        >
                          {stationsWithNoGeofence}
                        </span>
                      </p>
                      <h6 className="total-headers">Stations With No Geofence</h6>
                    </div>

                    <div className="data-container">
                      <div className="small-number-value">
                        <ExcelStationIssuesDownloadButton
                        fileName={stationFileName}
                        />
                      </div>
                      <h6 className="total-headers">Download Station Issues</h6>
                    </div>
                  </div>

                </div>
              </div>

              <div id="StockBox1" className="tab-content-flexbox">

                  {/* User controls */}
                  <div>
                    <h2 className="section-title">Current Stock Issues</h2>
                    <div className="user-controls-flexbox">
                      <div className="user-controls-element">
                        {isLoading &&
                          <div className="loading-icon"></div>
                        }
                      </div>
                    </div>
                  </div>

                  {/* Stock Issue Stacked Bar Chart - by issue type by customer*/}
                  <StackedCustomerBarChart
                    chartTitle="Issues By Customer"
                    stackedBarChartApi={stockIssues}
                    defaultTimeframe="1 month"
                    aggregateOn="stock_type"
                    inclNotTrackedStatus={showNotTracked}
                    style={{
                      width: "98%",
                      marginBottom: "1%",
                      paddingTop: "40px",
                    }}
                  />

              </div>

              <div id="StockBox1" className="tab-content-flexbox">

                  {/* User controls */}
                  <div>
                    <h2 className="section-title">Historic Stock Issues</h2>
                    <div className="user-controls-flexbox">
                      <div className="user-controls-element">
                        {isLoading &&
                          <div className="loading-icon"></div>
                        }
                      </div>
                    </div>
                  </div>

                  {/* Stock Issue Stacked Bar Chart - by issue type by date */}
                  <StackedHistoricBarChart
                    chartTitle="Issues By Type"
                    stackedBarChartApi={stockIssuesHistory}
                    defaultTimeframe="1 month"
                    aggregateOn="stock_type"
                    inclNotTrackedStatus={showNotTracked}
                    style={{
                      width: "98%",
                      marginBottom: "1%",
                      paddingTop: "40px",
                    }}
                  />

                  {/* Stock Issue Stacked Bar Chart - by customer by date */}
                  <StackedHistoricBarChart
                    chartTitle="Issues By Customer"
                    stackedBarChartApi={stockIssuesHistory}
                    defaultTimeframe="1 month"
                    aggregateOn="stock_customer"
                    inclNotTrackedStatus={showNotTracked}
                    style={{
                      width: "98%",
                      marginBottom: "1%",
                      paddingTop: "40px",
                    }}
                  />

              </div>
            </TabPanel>


            <TabPanel>
              <div>
                <div className="tab-content-flexbox">
                  {/*<div className="chart-flexbox">*/}
                  {/*  /!* Stacked barchart rental integration API *!/*/}
                  {/*  <StackedHistoricBarChart*/}
                  {/*      chartTitle="Rental API Usage"*/}
                  {/*      stackedBarChartApi={rentalApiStats}*/}
                  {/*      defaultTimeframe="1 week"*/}
                  {/*      aggregateOn="rental_api_metrics"*/}
                  {/*      legendPosition="bottom"*/}
                  {/*      style={{*/}
                  {/*        width: "98%",*/}
                  {/*        marginBottom: "1%",*/}
                  {/*        paddingTop: "40px",*/}
                  {/*      }}*/}
                  {/*    />*/}
                  {/*</div>*/}

                  <div className="chart-flexbox">
                    {/* Stacked barchart feature usage */}
                    <StackedHistoricBarChart
                        chartTitle="Portal Feature Usage (use download button for breakdown)"
                        stackedBarChartApi={featureUsage}
                        defaultTimeframe="1 week"
                        aggregateOn="feature_usage"
                        legendPosition="bottom"
                        style={{
                          width: "98%",
                          marginBottom: "1%",
                          paddingTop: "40px",
                        }}
                      />
                  </div>

                </div>
              </div>
            </TabPanel>
            <TabPanel>
              <div>

                <div className="tab-content-flexbox">
                  {/*Customer Summary Tables */}
                  <div id="btn-table">
                      <h4 className="table-name">Customer Summary</h4>

                      <ExcelCustTableDownloadButton
                          customerData={tableData}
                          deviceIssuesData={tableData}
                          fileName={fileName}
                      />
                  </div>

                  <div className="main-table">
                    <TableComponent
                      apiData={tableData}
                      sortMainColumn={sortMainColumn}
                      sortMainDirection={sortMainDirection}
                      handleMainSort={handleMainSort}
                      headers={showIssuesFromSoldCars === false ? (headerMainExclSold) : (headerMain) }
                    />
                  </div>

                  <div className="main-table">
                    <TableComponent
                      apiData={tableData}
                      sortMainColumn={sortsecColumn}
                      sortMainDirection={sortsecDirection}
                      handleMainSort={handlesecSort}
                      headers={headersec}
                    />
                  </div>
                </div>

                <div className="tab-content-flexbox">
                  <div className="map-data">
                    <div className="map">
                      <Map
                        mapData={countriesData}
                        handleCountrySelect={handleCountrySelect}
                      />
                    </div>
                    <div className="customer-list">
                      <h3>Country: {selectedCountry}</h3>
                      <ul>
                        {selectedCountry && tableData && (
                          <>
                            {tableData.map((customer) => {
                              if (locationData) {
                                const matchingLocation = locationData.find(
                                  (item) => item.clientName === customer.customer_name
                                );

                                if (
                                  matchingLocation &&
                                  matchingLocation.location === selectedCountry
                                ) {
                                  return (
                                    <li key={customer.id}>
                                      {customer.customer_name}: {customer.devices_installed_on_current_fleet} {' '}
                                      installed on fleet
                                    </li>
                                  );
                                }
                              }

                              return null;
                            })}
                          </>
                        )}
                      </ul>
                    </div>
                  </div>
                </div>

                <div className="tab-content-flexbox">
                  {/* Bar Chart of Various Counts by Customer */}
                  <BarChart barChartApi={tableData} exclSold={showIssuesFromSoldCars} />
                </div>

                <div className="tab-content-flexbox">
                  {/* Age charts */}
                  <div id="horizontal-barchart-container">
                    <div className="horizontal-block">
                      <HorizontalDeviceAgeBarChart
                        apiData={rmInstalledDeviceAges}
                        chartTitle="RentalMatics Device Age Range (Installed Devices)"
                        aggregateOn="customer"
                      />
                    </div>
                    <div className="horizontal-block">
                      <HorizontalDeviceAgeBarChart
                        apiData={custInstalledDeviceAges}
                        chartTitle="Customer Purchased Device Age Range (Installed)"
                        aggregateOn="customer"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </TabPanel>
          </Tabs>
        </div>
      </div>
    </div>
  </>
  );
};

export default Home;
