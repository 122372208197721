import React, { useEffect, useRef, useState } from "react";
import Chart from "chart.js/auto";
import { Tooltip } from 'chart.js';
import "../styles/Charts.css";
import * as Constants from "../services/Constants.js"

const DIStackedChart = ({
   stackedBarChartApi,
   chartTitle,
   legendPosition,
   style,
   inclSold,
   inclOkStatus,
   inclNotTrackedStatus
}) => {
  const chartRef = useRef(null);
  const [chartInstance, setChartInstance] = useState(null);

  useEffect(() => {
    var maxColumnSum = 0;
    var minAllowedValForAnnotation = 0;

    const defaultLegendClickHandler = Chart.defaults.plugins.legend.onClick;

    function aggregateData(data) {
      const aggregatedData = {};

      data.forEach((entry) => {
        var customer_identifier = entry.customer_identifier;
        if (!aggregatedData[customer_identifier]) {
          aggregatedData[customer_identifier] = {};
        }

        for (const key in entry) {
          if (key !== "timestamp" && key !== "customer_identifier") {
            if (!inclNotTrackedStatus && key.startsWith("Vehicle not tracked")) {
                  // Skip these entries
            } else {
              if (!aggregatedData[customer_identifier][key]) {
                aggregatedData[customer_identifier][key] = 0;
              }
              aggregatedData[customer_identifier][key] += entry[key];
            }
          }
        }
      });

      maxColumnSum = 0;
      for (const cust in aggregatedData) {
        var total_issues = 0;
        for (const item in aggregatedData[cust]) {
          total_issues += aggregatedData[cust][item];
        }
        if (total_issues > maxColumnSum) {
          maxColumnSum = total_issues;
        }
      }
      minAllowedValForAnnotation = maxColumnSum * 0.08;
      return aggregatedData;
    }


    function getDatasetKeys(data, labels) {
      var datasetKeys = [];
      let last_data = null;
      for (const cust in data) {
        last_data = data[cust];
        datasetKeys = Array.from(new Set(datasetKeys.concat(Object.keys(last_data))));
      }
      return datasetKeys;
    }

    const newLegendClickHandler = function (e, legendItem, legend) {
      let ci = legend.chart;
      dataSetWithHiddenRemoved(ci)
      defaultLegendClickHandler(e, legendItem, legend);
    };

    function addValuesInBars(chart) {
        var num_bars = chart.data.datasets[0].data.length;
        if (num_bars <= 32) {
          const ctx = chart.ctx;
          chart.data.datasets.forEach((dataset, datasetIndex) => {
            const meta = chart.getDatasetMeta(datasetIndex);
            meta.data.forEach((bar, index) => {
              const data = dataset.data[index];
              if (data > minAllowedValForAnnotation) {
                ctx.fillStyle = 'white';
                ctx.textAlign = 'center';
                ctx.textBaseline = 'center';
                const xPos = bar.x;
                const yPos = bar.y + (bar.height/2);
                var txt = data;
                ctx.fillText(txt, xPos, yPos);
              }
            });
          });
        }
      }

    function recalcMaxColumnSumFromUnhidden(datasets) {
      const maxArrayLen = datasets[0].data.length;
      const numUnhiddenLabels = datasets.length
      var myMax = 0;
      for (var i = 0; i < maxArrayLen; i++) {
        var thisSize = 0;
        for (var j = 0; j < numUnhiddenLabels; j++) {
          thisSize += datasets[j].data[i]
        }
        if (thisSize > myMax) {
          myMax = thisSize;
        }
      }
      maxColumnSum = myMax;
      minAllowedValForAnnotation = maxColumnSum * 0.08;
    }

    function dataSetWithHiddenRemoved(chart) {
      var hiddenLabels = [];
      for (var i = 0; i < chart.data.datasets.length; i++) {
        if (!chart.isDatasetVisible(i)) {
          hiddenLabels.push(chart.data.datasets[i].label);
        }
      }
      var reversedIndex = 0;
      var newDatasets = chart.data.datasets.slice().reverse();
      newDatasets = newDatasets.filter(function(item) {
        return (!hiddenLabels.includes(item.label));
      })
      for (i = newDatasets.length - 1; i >= 0; i--) {
        newDatasets[i].reversedIndex = reversedIndex++;
      }
      recalcMaxColumnSumFromUnhidden(newDatasets);
      return newDatasets;
    }


    if (stackedBarChartApi) {
      const canvas = chartRef.current;
      const ctx = canvas.getContext("2d");

      let filteredData = stackedBarChartApi;

      const aggregatedData = aggregateData(filteredData);

      const labels = Object.keys(aggregatedData);

      const datasetKeys = getDatasetKeys(aggregatedData, labels);

      function mapDatasetKeys(key, index, stack, myBackgroundColor, myHoverBackgroundColor) {
        var backgroundColor = myBackgroundColor[index % myBackgroundColor.length];
        var hoverBackgroundColor = myHoverBackgroundColor[index % myHoverBackgroundColor.length];
        return {
          label: key,
          data: labels.map((cust) => aggregatedData[cust][key]),
          backgroundColor: backgroundColor,
          hoverBackgroundColor: hoverBackgroundColor,
          stack: stack,
        };
      }

      var datasets = datasetKeys.map((key, index) => mapDatasetKeys(key, index, 'Stack0', Constants.backgroundColor, Constants.hoverBackgroundColor));

      const chartData = {
        labels: labels.map((cust) => cust),
        datasets: datasets,
      };

      var position = "right";
      var align = "start"; // Align the legend to the start (left) side
      if (legendPosition) {
        position = legendPosition;
        align = "center"
      }

      const chartPlugins = [
      {
          // Draw values on the bars
          afterDatasetDraw: (chart) => addValuesInBars(chart)
        }
      ]

      Tooltip.positioners.bottomright = function (chartElements, coordinates) {
        var pos = {
          y: this.chart.chartArea.bottom, // Set y-coordinate to the bottom of the chart
          x: this.chart.chartArea.right+10, // Set x-coordinate to the right of the chart
        }
        return pos;
      };

      var tooltip = {
        xAlign: "left",
        mode: "x",
        position: "average",
        intersect: false, // Disable intersect to only trigger for the hovered bar
        backgroundColor: "#000000",
        callbacks: {
          beforeBody: () => {
            total = 0;
          },
          label: function (context) {
            // Handle case where tooltip appears reversed order
            var label;
            var value;
            var myIndex;
            const modifiedDatasets = dataSetWithHiddenRemoved(context.chart);
            var thisLabel = datasets[context.datasetIndex].label
            myIndex = -1;
            modifiedDatasets.forEach((entry) => {
              if (thisLabel === entry.label) {
                myIndex = entry.reversedIndex;
              }
            });
            if (myIndex === -1) {
              return ``; // Can sometimes happen if tooltip used during animation
            }
            label = modifiedDatasets[myIndex].label || "";
            value = modifiedDatasets[myIndex].data[context.dataIndex] || 0;
            total += value;
            return `${label}: ${value}`;
          },
          labelColor: function (context) {
            // Handle case where tooltip appears reversed order
            var myColourIndex;
            var backgroundColor;
            const modifiedDatasets = dataSetWithHiddenRemoved(context.chart);
            var thisLabel = datasets[context.datasetIndex].label
            myColourIndex = -1;
            modifiedDatasets.forEach((entry) => {
              if (thisLabel === entry.label) {
                myColourIndex = entry.reversedIndex;
              }
            });
            if (myColourIndex === -1) {
              return ``; // Can sometimes happen if tooltip used during animation
            }
            backgroundColor = modifiedDatasets[myColourIndex].backgroundColor;
            return {backgroundColor: backgroundColor};
          },
          afterBody: function (context) {
            return `Total: ${total || 0}`;
          },
        },
      };

      var legendPlug = {
        display: true,
        reverse: true,
        position: position,
        align: align,
        onClick: newLegendClickHandler,
        labels: {
          font: {
            size: 12
          }
        }
      };

      tooltip.position = "bottomright";
      tooltip.caretSize = 0;

      let total = 0;
      const options = {
            indexAxis: "x", // Use "x" as the index axis for horizontal chart
            maintainAspectRatio: false,
            animation: false, // All graphs are redrawn when any API returns, making animation look bad. So disable
            responsive: true,
            scales: {
              x: {
                stacked: true,
                beginAtZero: true,
              },
              y: {
                stacked: true,
                grid: {
                  display: false,
                },
              },
            },
            plugins: {
              legend: legendPlug,
              title: {
                display: (chartTitle)?true:false,
                text: chartTitle,
                color: "#009879",
                font: {
                  size: 16,
                },
                padding: {
                  top: 15,
                  bottom: 15,
                },
              },
              tooltip: tooltip,
            },
      };

      if (chartInstance) {
        chartInstance.data = chartData;
        chartInstance.options = options;
        chartInstance.update();
      } else {
        const newChartInstance = new Chart(ctx, {
          type: "bar",
          data: chartData,
          options: options,
          plugins: chartPlugins,
        });
        setChartInstance(newChartInstance);
      }
    }
  }, [stackedBarChartApi,
      chartTitle,
      legendPosition,
      style,
      chartInstance,
      inclSold,
      inclOkStatus,
      inclNotTrackedStatus]);

  return (
    <div
      className="linegraph-container"
      style={{ ...style, position: "relative" }}
    >
      <canvas
        ref={chartRef}
        id="DIStackedBarChartDevices"
        style={{ width: "100%", height: "450px" }}
      />
    </div>
  );
};


export default DIStackedChart;
