import React, { useEffect, useRef, useState } from "react";
import Chart from "chart.js/auto";
import { Tooltip } from 'chart.js';
import "../styles/Charts.css";
import * as Constants from "../services/Constants.js"
import ColourUtils from "../services/ColourUtils"
import ExcelHistoricStatsDownloadButton from "./ExcelHistoricStatsDownloadButton";


const DIStackedChart = ({
   stackedBarChartApi,
   chartTitle,
   legendPosition,
   style,
   defaultTimeframe,
   aggregateOn,
   inclSold,
   inclOkStatus,
   inclNotTrackedStatus,
   showDownload
}) => {
  const chartRef = useRef(null);
  const [timeframe, setTimeframe] = useState(defaultTimeframe);
  const [chartInstance, setChartInstance] = useState(null);
  const [sheetName, setSheetName] = useState(null);

  useEffect(() => {
    var maxColumnSum = 0;
    var minAllowedValForAnnotation = 0;

    const defaultLegendClickHandler = Chart.defaults.plugins.legend.onClick;

    function aggregateData(data) {
      const aggregatedData = {};

      data.forEach((entry) => {
        var timestamp = entry.timestamp;
        if (aggregateOn === "rental_api_metrics") {
          timestamp = timestamp.split(" ")[0]; // Extract date portion from timestamp
        }
        if (!aggregatedData[timestamp]) {
          aggregatedData[timestamp] = {};
        }

        if (aggregateOn === "customers") {
          setSheetName("Customers");
          var cust = "Unknown";
          var total_issues = 0;
          for (const key in entry) {
            if (key === "customer_identifier") {
              cust = entry[key]
            } else {
              if (key !== "timestamp") {
                  if (!inclOkStatus && key.startsWith("OK:")) {
                    // Skip OK: entries
                  } else {
                    if (inclSold) {
                      total_issues += entry[key]['count'];
                    } else {
                      total_issues += entry[key]['count_excl_sold'];
                    }
                  }
              }
            }
          }
          aggregatedData[timestamp][cust] = total_issues;
        } else if (aggregateOn === "stock_customer")  {
          setSheetName("Customer");
          var stock_customer = "Unknown";
          var total_stock_issues = 0;
          for (const key in entry) {
            if (key === "customer_identifier") {
              stock_customer = entry[key]
            } else {
              if (key !== "timestamp") {
                if (!inclNotTrackedStatus && key.startsWith("Vehicle not tracked")) {
                    // Skip these entries
                } else {
                  total_stock_issues += entry[key];
                }
              }
            }
          }
          aggregatedData[timestamp][stock_customer] = total_stock_issues;
        } else if (aggregateOn === "stock_type")  {
          setSheetName("Stock Issues");
          for (const key in entry) {
            if (key !== "timestamp" && key !== "customer_identifier") {
              if (!inclNotTrackedStatus && key.startsWith("Vehicle not tracked")) {
                    // Skip these entries
              } else {
                if (!aggregatedData[timestamp][key]) {
                  aggregatedData[timestamp][key] = 0;
                }
                aggregatedData[timestamp][key] += entry[key];
              }
            }
          }
        } else if (aggregateOn === "onboardings")  {
          setSheetName("Customers");
          cust = entry["customer_identifier"];
          if (!aggregatedData[timestamp][cust]) {
              aggregatedData[timestamp][cust] = {};
          }
          aggregatedData[timestamp][cust]["onboarded_today"] = entry["onboarded_today"];
          aggregatedData[timestamp][cust]["offboarded_today"] = entry["offboarded_today"];
        } else if (aggregateOn === "issue_type")  {
          setSheetName("Issues");
          for (const key in entry) {
            if (key !== "timestamp" && key !== "customer_identifier" && key !== "manufacturer" && key !== "model_type") {
              if (!inclOkStatus && key.startsWith("OK:")) {
                // Skip OK: entries
              } else {
                if (!aggregatedData[timestamp][key]) {
                  aggregatedData[timestamp][key] = 0;
                }
                if (inclSold) {
                  aggregatedData[timestamp][key] += entry[key]['count'];
                } else {
                  aggregatedData[timestamp][key] += entry[key]['count_excl_sold'];
                }
              }
            }
          }
        } else if (aggregateOn === "model_type")  {
          setSheetName("Models");
          for (const key in entry) {
            if (key !== "timestamp" && key !== "customer_identifier") {
              if (!aggregatedData[timestamp][key]) {
                aggregatedData[timestamp][key] = 0;
              }
              if (inclSold) {
                aggregatedData[timestamp][key] += entry[key]['count'];
              } else {
                aggregatedData[timestamp][key] += entry[key]['count_excl_sold'];
              }
            }
          }
        } else if (aggregateOn === "actions_issues")  {
          setSheetName("Actions");
          for (const key in entry) {
            if (key !== "timestamp" && key !== "customer_identifier") {
              if (!aggregatedData[timestamp][key]) {
                aggregatedData[timestamp][key] = 0;
              }
              aggregatedData[timestamp][key] += entry[key];
            }
          }
        } else if (aggregateOn === "portal_by_role")  {
          var role = entry["role"];
          if (!(role in aggregatedData[timestamp])) {
            aggregatedData[timestamp][role] = 0
          }
          aggregatedData[timestamp][role] += entry["day_count"];
        } else if (aggregateOn === "feature_usage")  {
          var feature = entry["feature"];
          var source = entry["source"];
          if (source === 'MobileApp') {
              feature = 'MobileApp: ' + feature;
          } else {
              feature = 'Portal: ' + feature;
          }
          if (!(feature in aggregatedData[timestamp])) {
            aggregatedData[timestamp][feature] = 0
          }
          aggregatedData[timestamp][feature] += entry["day_count"];
        } else if (aggregateOn === "rental_api_metrics")  {
          var severity = entry["severity"];
            if (severity === 'INFO') {
              severity = 'INFO (2XX)';
            } else if (severity === 'WARNING') {
              severity = 'WARNING (3XX)';
            } else if (severity === 'ALERT') {
              severity = 'ALERT (4XX)';
            } else if (severity === 'CRITICAL') {
              severity = 'CRITICAL (5XX)';
            }
          if (!(severity in aggregatedData[timestamp])) {
            aggregatedData[timestamp][severity] = 0
          }
          aggregatedData[timestamp][severity] += entry["count"];
        }
      });

      maxColumnSum = 0;
      for (const ts in aggregatedData) {
        var total_issues = 0;
        for (const item in aggregatedData[ts]) {
          total_issues += aggregatedData[ts][item];
        }
        if (total_issues > maxColumnSum) {
          maxColumnSum = total_issues;
        }
      }
      minAllowedValForAnnotation = maxColumnSum * 0.08;
      return aggregatedData;
    }

    function compareDatasetValues(last_data, b, a) {
      let last_a = 0;
      if (a in last_data) {
        last_a = last_data[a];
      }
      let last_b = 0;
      if (b in last_data) {
        last_b = last_data[b];
      }
      return last_b - last_a;
    }

    function getDatasetKeys(data, labels) {
      var datasetKeys = [];
      var key;
      let last_data = null;
      if (aggregateOn === "customers" || aggregateOn === "onboardings") {
        for (key in data) {
          last_data = data[key];
          datasetKeys = Array.from(new Set(datasetKeys.concat(Object.keys(last_data))));
        }
      } else {
        for (const timestamp in data) {
          last_data = data[timestamp];
          datasetKeys = Array.from(new Set(datasetKeys.concat(Object.keys(last_data))));
        }
      }
      if (aggregateOn !== "rental_api_metrics" ) {
        /* Order the keys according to values in last_data */
        datasetKeys.sort((a, b) => compareDatasetValues(last_data, b, a));
      }
      return datasetKeys;
    }

    const newLegendClickHandler = function (e, legendItem, legend) {
      let ci = legend.chart;
      dataSetWithHiddenRemoved(ci)
      defaultLegendClickHandler(e, legendItem, legend);
    };

    function addValuesInBars(chart) {
        if (aggregateOn === "onboardings") {
          return;
        }
        var num_bars = chart.data.datasets[0].data.length;
        if ( (num_bars <= 8) || ((num_bars <= 32) && !(aggregateOn === "actions_issues"))) {
          const ctx = chart.ctx;
          chart.data.datasets.forEach((dataset, datasetIndex) => {
            const meta = chart.getDatasetMeta(datasetIndex);
            meta.data.forEach((bar, index) => {
              const data = dataset.data[index];
              const label = dataset.label;
              if (data > minAllowedValForAnnotation) {
                ctx.fillStyle = 'white';
                ctx.textAlign = 'center';
                ctx.textBaseline = 'center';
                const xPos = bar.x;
                const yPos = bar.y + (bar.height/2);
                var txt = data;
                if (!showValueOnly) {
                  if ((num_bars <= 8 ) && ((aggregateOn === "model_type") || (aggregateOn === "customers") || (aggregateOn === "stock_customer"))) {
                    txt = `${label}: ${data}`;
                  }
                }
                ctx.fillText(txt, xPos, yPos);
              }
            });
          });
        }
      }

    function recalcMaxColumnSumFromUnhidden(datasets) {
      const maxArrayLen = datasets[0].data.length;
      const numUnhiddenLabels = datasets.length
      var myMax = 0;
      for (var i = 0; i < maxArrayLen; i++) {
        var thisSize = 0;
        for (var j = 0; j < numUnhiddenLabels; j++) {
          thisSize += datasets[j].data[i]
        }
        if (thisSize > myMax) {
          myMax = thisSize;
        }
      }
      maxColumnSum = myMax;
      minAllowedValForAnnotation = maxColumnSum * 0.08;
    }

    function dataSetWithHiddenRemoved(chart) {
      var hiddenLabels = [];
      for (var i = 0; i < chart.data.datasets.length; i++) {
        if (!chart.isDatasetVisible(i)) {
          hiddenLabels.push(chart.data.datasets[i].label);
        }
      }
      var reversedIndex = 0;
      var newDatasets = chart.data.datasets.slice().reverse();
      newDatasets = newDatasets.filter(function(item) {
        return (!hiddenLabels.includes(item.label));
      })
      for (i = newDatasets.length - 1; i >= 0; i--) {
        newDatasets[i].reversedIndex = reversedIndex++;
      }
      recalcMaxColumnSumFromUnhidden(newDatasets);
      return newDatasets;
    }

    function itemAsDate(item) {
      var i;
      if ('date' in item) {
        i = item.date;
      } else {
        i = item.timestamp;
      }
      return new Date(i);
    }

    function timestampToLabel(ts) {
        const date = new Date(ts).toISOString().split('T')[0];
        if (aggregateOn === "rental_api_metrics") {
          const hrs = new Date(ts).getHours();
          const mins = new Date(ts).getMinutes();
          return date + ' ' + String(hrs).padStart(2, '0') + ':' + String(mins).padStart(2, '0');
        } else {
          return date;
        }
    }

    if (stackedBarChartApi) {
      const canvas = chartRef.current;
      const ctx = canvas.getContext("2d");

      let filteredData = stackedBarChartApi;
      if (timeframe === "max") {
        filteredData = stackedBarChartApi.filter(
          (entry) => itemAsDate(entry) >= new Date("2023-08-16")
        );
      } else if (timeframe === "1 year") {
        const oneYearAgo = new Date();
        oneYearAgo.setFullYear(oneYearAgo.getFullYear() - 1);
        filteredData = stackedBarChartApi.filter(
          (entry) => itemAsDate(entry) >= oneYearAgo
        );
      } else if (timeframe === "1 month") {
        const oneMonthAgo = new Date();
        oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1);
        filteredData = stackedBarChartApi.filter(
          (entry) => itemAsDate(entry) >= oneMonthAgo
        );
      } else if (timeframe === "1 week") {
        const oneWeekAgo = new Date();
        oneWeekAgo.setDate(oneWeekAgo.getDate() - 7);
        filteredData = stackedBarChartApi.filter(
          (entry) => itemAsDate(entry) >= oneWeekAgo
        );
      }

      const aggregatedData = aggregateData(filteredData);

      const labels = Object.keys(aggregatedData);

      const datasetKeys = getDatasetKeys(aggregatedData, labels);

      function mapDatasetKeys(key, index, stack, myBackgroundColor, myHoverBackgroundColor) {
        var backgroundColor = myBackgroundColor[index % myBackgroundColor.length];
        var hoverBackgroundColor = myHoverBackgroundColor[index % myHoverBackgroundColor.length];
        if (aggregateOn === "issue_type") {
          backgroundColor = ColourUtils.getAnomalyBgColour(key);
          hoverBackgroundColor = ColourUtils.getAnomalyHoverColour(key);
        } else if (aggregateOn === "rental_api_metrics")  {
          backgroundColor = ColourUtils.getSeverityBgColour(key);
          hoverBackgroundColor = ColourUtils.getSeverityHoverColour(key);
        }
        return {
          label: key,
          data: labels.map((date) => aggregatedData[date][key]),
          backgroundColor: backgroundColor,
          hoverBackgroundColor: hoverBackgroundColor,
          stack: stack,
        };
      }

      function getOnboardingsColourGeneric(stack, index, opacity) {
        if (stack === 'Stack0') {
           const gradient = 255-2*index;
           return "rgba(0, " + gradient + ", 50, " + opacity + ")";
        }
        const gradient = 255-3*index;
        return "rgba(" + gradient + ", 30, 0, " + opacity + ")";
      }

      function getOnboardingsBgColour(stack, index) {
        return getOnboardingsColourGeneric(stack, index, "1.0");
      }

      function getOnboardingsHoverColour(stack, index) {
        return getOnboardingsColourGeneric(stack, index, "1.0");
      }

      function mapOnboardingItem(stack, date, cust) {
        var ret = 0;
        if (stack === 'Stack0') {
          try {
            ret = aggregatedData[date][cust]["onboarded_today"];
          } catch (error) {
            ret = 0;
          }
        } else {
          try {
            ret = aggregatedData[date][cust]["offboarded_today"];
          } catch (error) {
            ret = 0;
          }
        }
        return ret;
      }

      function mapOnboardings(cust, index, stack) {
         return {
            label: cust,
            data: labels.map((date) => mapOnboardingItem(stack, date, cust)),
            backgroundColor: getOnboardingsBgColour(stack, index),
            hoverBackgroundColor: getOnboardingsHoverColour(stack, index),
            borderColour: getOnboardingsBgColour(stack, index),
            stack: stack,
          };
      }

      var datasets = null;
      if (aggregateOn === "actions_issues") {
        // This data gets displayed as two 'stacks'
        const rmDatasetKeys = [];
        const custDatasetKeys = [];
        datasetKeys.forEach((key) => {
          if (key.startsWith("RM Action")) {
            rmDatasetKeys.push(key);
          } else if (key.startsWith("Customer Action")) {
            custDatasetKeys.push(key);
          }
        });
        datasets = rmDatasetKeys.map((key, index) => mapDatasetKeys(key, index, 'Stack0', Constants.backgroundColor, Constants.hoverBackgroundColor)).concat(
          custDatasetKeys.map((key, index) => mapDatasetKeys(key, index, 'Stack1', Constants.secondaryBackgroundColor, Constants.secondaryHoverBackgroundColor)));
      } else if (aggregateOn === "onboardings") {
        // This data gets displayed as two 'stacks'
        const onboardingsDatasetKeys = [];
        const offboardingsDatasetKeys = [];
        datasetKeys.forEach((cust) => {
            onboardingsDatasetKeys.push(cust);
            offboardingsDatasetKeys.push(cust);
        });
        datasets = onboardingsDatasetKeys.map((cust, index) => mapOnboardings(cust, index, 'Stack0')).concat(
          offboardingsDatasetKeys.map((cust, index) => mapOnboardings(cust, index, 'Stack1')));
      } else {
        datasets = datasetKeys.map((key, index) => mapDatasetKeys(key, index, 'Stack0', Constants.backgroundColor, Constants.hoverBackgroundColor));
      }

      const chartData = {
        labels: labels.map((date) => timestampToLabel(date)),
        datasets: datasets,
      };

      var position = "right";
      var align = "start"; // Align the legend to the start (left) side
      if (legendPosition) {
        position = legendPosition;
        align = "center"
      }

      const chartPlugins = [
      {
          // Draw values on the bars
          afterDatasetDraw: (chart) => addValuesInBars(chart)
        }
      ]

      Tooltip.positioners.bottomright = function (chartElements, coordinates) {
        var pos = {
          y: this.chart.chartArea.bottom, // Set y-coordinate to the bottom of the chart
          x: this.chart.chartArea.right+10, // Set x-coordinate to the right of the chart
        }
        return pos;
      };

      var tooltip = {
        xAlign: "left",
        mode: "x",
        position: "average",
        intersect: false, // Disable intersect to only trigger for the hovered bar
        backgroundColor: "#000000",
        callbacks: {
          beforeBody: () => {
            total = 0;
          },
          label: function (context) {
            // Handle case where tooltip appears reversed order
            var label;
            var value;
            var myIndex;
            if (aggregateOn === "actions_issues" || aggregateOn === "feature_usage" || aggregateOn === "onboardings") {
              label = datasets[context.datasetIndex].label || "";
              value = datasets[context.datasetIndex].data[context.dataIndex] || 0;
            } else {
              const modifiedDatasets = dataSetWithHiddenRemoved(context.chart);
              var thisLabel = datasets[context.datasetIndex].label
              myIndex = -1;
              modifiedDatasets.forEach((entry) => {
                if (thisLabel === entry.label) {
                  myIndex = entry.reversedIndex;
                }
              });
              if (myIndex === -1) {
                return ``; // Can sometimes happen if tooltip used during animation
              }
              label = modifiedDatasets[myIndex].label || "";
              value = modifiedDatasets[myIndex].data[context.dataIndex] || 0;
            }
            total += value;
            return `${label}: ${value}`;
          },
          labelColor: function (context) {
            // Handle case where tooltip appears reversed order
            var myColourIndex;
            var backgroundColor;
            if (aggregateOn === "actions_issues" || aggregateOn === "feature_usage") {
              backgroundColor = datasets[context.datasetIndex].backgroundColor;
            }
            else if (aggregateOn === "onboardings") {
              backgroundColor = getOnboardingsBgColour(context.dataset.stack , context.datasetIndex);
            } else {
              const modifiedDatasets = dataSetWithHiddenRemoved(context.chart);
              var thisLabel = datasets[context.datasetIndex].label
              myColourIndex = -1;
              modifiedDatasets.forEach((entry) => {
                if (thisLabel === entry.label) {
                  myColourIndex = entry.reversedIndex;
                }
              });
              if (myColourIndex === -1) {
                return ``; // Can sometimes happen if tooltip used during animation
              }
              backgroundColor = modifiedDatasets[myColourIndex].backgroundColor;
            }
            return {backgroundColor: backgroundColor};
          },
          afterBody: function (context) {
            return `Total: ${total || 0}`;
          },
          title: function (context) {
            if (aggregateOn === "onboardings") {
              const stackName = (context[0].dataset.stack === "Stack0")?"Onboarded":"Offboarded";
              return stackName + ' ' + context[0].label;
            } else {
              return context[0].label;
            }
          },
        },
      };

      var showValueOnly = false;

      var legendPlug = {
        display: true,
        reverse: true,
        position: position,
        align: align,
        onClick: newLegendClickHandler,
        labels: {
          font: {
            size: 12
          }
        }
      };

      if (aggregateOn === "actions_issues" || aggregateOn === "onboardings") {
        legendPlug.display = false;
      } else {
        //showValueOnly = true;
        tooltip.position = "bottomright";
        tooltip.caretSize = 0;
      }

      let total = 0;
      const options = {
            indexAxis: "x", // Use "x" as the index axis for horizontal chart
            maintainAspectRatio: false,
            animation: false, // All graphs are redrawn when any API returns, making animation look bad. So disable
            responsive: true,
            scales: {
              x: {
                stacked: true,
                beginAtZero: true,
              },
              y: {
                stacked: true,
                grid: {
                  display: false,
                },
              },
            },
            plugins: {
              legend: legendPlug,
              title: {
                display: (chartTitle)?true:false,
                text: chartTitle,
                color: "#009879",
                font: {
                  size: 16,
                },
                padding: {
                  top: 15,
                  bottom: 15,
                },
              },
              tooltip: tooltip,
            },
      };

      if (chartInstance) {
        chartInstance.data = chartData;
        chartInstance.options = options;
        chartInstance.update();
      } else {
        const newChartInstance = new Chart(ctx, {
          type: "bar",
          data: chartData,
          options: options,
          plugins: chartPlugins,
        });
        setChartInstance(newChartInstance);
      }
    }
  }, [stackedBarChartApi,
      chartTitle,
      legendPosition,
      style,
      timeframe,
      aggregateOn,
      chartInstance,
      defaultTimeframe,
      inclSold,
      inclOkStatus,
      inclNotTrackedStatus,
      showDownload]);

  const handleTimeframeChange = (selectedTimeframe) => {
    setTimeframe(selectedTimeframe);
  };

  return (
    <div
      className="linegraph-container"
      style={{ ...style, position: "relative" }}
    >
      <canvas
        ref={chartRef}
        id="DIStackedBarChartDevices"
        style={{ width: "100%", height: "450px" }}
      />
      <div
        className="timeframe-buttons"
        style={{ position: "absolute", top: "5px", left: "40px" }}
      >
        <button
          className={timeframe === "max" ? "selected" : ""}
          onClick={() => handleTimeframeChange("max")}
        >
          Max
        </button>
        <button
          className={timeframe === "1 year" ? "selected" : ""}
          onClick={() => handleTimeframeChange("1 year")}
        >
          1Y
        </button>
        <button
          className={timeframe === "1 month" ? "selected" : ""}
          onClick={() => handleTimeframeChange("1 month")}
        >
          1M
        </button>
        <button
          className={timeframe === "1 week" ? "selected" : ""}
          onClick={() => handleTimeframeChange("1 week")}
        >
          1W
        </button>
        {showDownload && (
          <ExcelHistoricStatsDownloadButton
            historicStats={stackedBarChartApi}
            types={aggregateOn}
            inclSold={inclSold}
            sheetName={sheetName}
          />
        )}
      </div>
    </div>
  );
};

DIStackedChart.defaultProps = {
  showDownload: true
};

export default DIStackedChart;
