import React, { useEffect, useRef, useState } from "react";
import Chart from "chart.js/auto";
import "../styles/Charts.css";

const LineChart = ({ lineChartApi, style, inclSold, showTrial }) => {
  const chartRef = useRef(null);
  const [timeframe, setTimeframe] = useState("1 month");

  // line chart total installs
  useEffect(() => {
    let chartInstance = null;

    if (lineChartApi) {
      // Helper function to get the data based on the selected timeframe
      const getDataByTimeframe = (timeframe) => {
        switch (timeframe) {
          case "1 week":
            return lineChartApi.slice(-7);

          case "1 month":
            return lineChartApi.slice(-30);

          case "1 year":
            return lineChartApi.slice(-365);

          default:
            return lineChartApi.slice(getIndexForMax());
        }
      };

      const getIndexForMax = () => {
        const maxDate = new Date("2023-06-17").toLocaleDateString("en-IE");
        return lineChartApi.findIndex((item) => {
          const date = new Date(item.hour);
          const formattedDate = date.toLocaleDateString("en-IE");
          return formattedDate === maxDate;
        });
      };

      const canvas = chartRef.current;
      const ctx = canvas.getContext("2d");

      const labels = [];
      const installedDevicesData = [];
      const installedDevicesExclSold = [];
      const healthyDevicesData = [];
      const healthyDevicesExclSold = [];
      const totalFleetData = [];
      const deviceIssues = [];
      const deviceIssuesExclSold = [];
      const actionsRm = [];
      const actionsCustomer = [];

      // Get the data based on the selected timeframe
      const trimmedData = getDataByTimeframe(timeframe);

      trimmedData.forEach((item) => {
        const date = new Date(item.hour);
        //const formattedDate = date.toLocaleDateString("en-IE");
        const formattedDate = date.toISOString().split('T')[0];

        labels.push(formattedDate);
        installedDevicesData.push(item.total_installs);
        installedDevicesExclSold.push(item.total_installs_excl_sold);
        totalFleetData.push(item.total_fleet);
        deviceIssuesExclSold.push(item.device_issues_excl_sold);
        deviceIssues.push(item.device_issues);
        healthyDevicesData.push(item.total_installs-item.device_issues)
        healthyDevicesExclSold.push(item.total_installs_excl_sold-item.device_issues_excl_sold)
        actionsRm.push(item.actions_rm);
        actionsCustomer.push(item.actions_customer);
      });

      var datasets = [
          {
            data: totalFleetData,
            label: "Total Fleet",
            fill: true,
            backgroundColor: "rgba(0, 152, 121, 0.1)",
            borderColor: "rgba(0, 152, 121, 1)",
            pointBackgroundColor: Array(totalFleetData.length).fill(
              "rgba(0, 152, 121, 1)"
            ),
            yAxisID: "y",
            hidden: true,
          },
          {
            data: installedDevicesData,
            label: "Installed Incl Sold",
            fill: true,
            backgroundColor: "rgba(75, 192, 192, 0.1)",
            borderColor: "rgba(75, 192, 192, 1)",
            pointBackgroundColor: Array(installedDevicesData.length).fill(
              "rgba(75, 192, 192, 1)"
            ),
            yAxisID: "y",
            hidden: true,
          },
          {
            data: installedDevicesExclSold,
            label: "Installed Devices",
            fill: true,
            backgroundColor: "rgba(75, 192, 192, 0.1)",
            borderColor: "rgba(75, 192, 192, 1)",
            pointBackgroundColor: Array(installedDevicesExclSold.length).fill(
              "rgba(75, 192, 192, 1)"
            ),
            yAxisID: "y",
            hidden: true,
          },
          {
            data: deviceIssues,
            label: "Issues Incl Sold",
            fill: true,
            backgroundColor: "rgba(245, 135, 66, 0.1)",
            borderColor: "rgba(245, 135, 66, 1)",
            pointBackgroundColor: Array(deviceIssues.length).fill(
              "rgba(245, 135, 66, 1)"
            ),
            yAxisID: "y1",
            hidden: !inclSold,
          },
          {
            data: deviceIssuesExclSold,
            label: "Devices Issues",
            fill: true,
            backgroundColor: "rgba(255, 0, 0, 0.1)",
            borderColor: "red",
            pointBackgroundColor: Array(deviceIssuesExclSold.length).fill(
              "rgba(255, 0, 0, 1)"
            ),
            yAxisID: "y1",
            hidden: inclSold,
          },
          {
            data: healthyDevicesData,
            label: "Healthy Incl Sold",
            fill: true,
            backgroundColor: "rgba(0, 200, 80, 0.1)",
            borderColor: "rgba(0, 200, 80, 1)",
            pointBackgroundColor: Array(healthyDevicesData.length).fill(
              "rgba(0, 200, 80, 1)"
            ),
            yAxisID: "y",
            hidden: !inclSold,
          },
          {
            data: healthyDevicesExclSold,
            label: "Healthy Installs",
            fill: true,
            backgroundColor: "rgba(0, 200, 80, 0.1)",
            borderColor: "rgba(0, 200, 80, 1)",
            pointBackgroundColor: Array(healthyDevicesExclSold.length).fill(
              "rgba(0, 200, 80, 1)"
            ),
            yAxisID: "y",
            hidden: inclSold,
          },
          {
            data: actionsRm,
            label: "RM Action",
            fill: true,
            backgroundColor: "rgba(230, 162, 46, 0.1)",
            borderColor: "rgba(230, 162, 46, 1)",
            pointBackgroundColor: Array(installedDevicesData.length).fill(
              "rgba(230, 162, 46, 1)"
            ),
            yAxisID: "y1",
          },
          {
            data: actionsCustomer,
            label: "Customer Action",
            fill: true,
            backgroundColor: "rgba(215, 46, 230, 0.1)",
            borderColor: "rgba(215, 46, 230, 1)",
            pointBackgroundColor: Array(installedDevicesData.length).fill(
              "rgba(215, 46, 230, 1)"
            ),
            yAxisID: "y1",
          },
        ];

      const chartData = {
        labels: labels,
        datasets: datasets,
      };

      if (chartInstance) {
        chartInstance.data = chartData;
        chartInstance.update();
      } else {
        chartInstance = new Chart(ctx, {
          type: "line",
          data: chartData,
          options: {
            maintainAspectRatio: true,
            responsive: true,
            plugins: {
              legend: {
                position: "bottom",
                display: true,
                align: "center", // Align the labels to the right
                fullWidth: false,
              },
              tooltip: {
                mode: "index",
                intersect: false,
                callbacks: {
                  // Customize the tooltip label
                  label: function (context) {
                    const datasetLabel = context.dataset.label || "";
                    const value = context.parsed.y;
                    return `${datasetLabel}: ${value}`;
                  },
                  afterBody: () => {
                    return `NB: Actions calculated at different times to issues - counts may not match`;
                  },
                },
              },
            },
            hover: {
              mode: "index",
              intersect: false,
            },

            scales: {
              y: {
                type: "linear",
                display: "auto",
                position: "left",
              },
              y1: {
                type: "linear",
                display: "auto",
                position: "right",
              },
            },
          },
        });
      }
    }

    return () => {
      if (chartInstance) {
        chartInstance.destroy();
      }
    };
  }, [lineChartApi, timeframe, inclSold, showTrial]);

  // Function to handle button click and update the selected timeframe
  const handleTimeframeChange = (selectedTimeframe) => {
    setTimeframe(selectedTimeframe);
  };

  return (
    <div
      className="linegraph-container"
      style={{ ...style, position: "relative" }}
    >
      <canvas ref={chartRef} id="lineChartDevices" />
      <div
        className="timeframe-buttons"
        style={{ position: "absolute", top: "4px", left: "10px" }}
      >
        <button
          className={timeframe === "max" ? "selected" : ""}
          onClick={() => handleTimeframeChange("max")}
        >
          Max
        </button>
        <button
          className={timeframe === "1 year" ? "selected" : ""}
          onClick={() => handleTimeframeChange("1 year")}
        >
          1Y
        </button>
        <button
          className={timeframe === "1 month" ? "selected" : ""}
          onClick={() => handleTimeframeChange("1 month")}
        >
          1M
        </button>
        <button
          className={timeframe === "1 week" ? "selected" : ""}
          onClick={() => handleTimeframeChange("1 week")}
        >
          1W
        </button>
      </div>
    </div>
  );
};

export default LineChart;
